$(function () {
  // スムーススクロール
  $('a[href^="#"]').on('click', function () {
    let href = $(this).attr('href');
    let target = $(href == '#' || href == '' ? 'html' : href);
    let position = target.offset().top;
    let speed = 500;
    $('html, body').animate(
      {
        scrollTop: position,
      },
      speed,
      'swing',
    );
    return false;
  });

  // Page Top
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 300) {
      $('.toPageTop').fadeIn();
    } else {
      $('.toPageTop').fadeOut();
    }
  });

  // PC Menu
  $('.l-header__nav__item--service').hover(
    function () {
      $('.l-header__nav').addClass('hover');
    },
    function () {
      $('.l-header__nav').removeClass('hover');
    },
  );

  // SP Menu
  $('.spMenu').on('click', function () {
    $(this).toggleClass('is-open');
    $('.l-header__nav').toggleClass('is-open');
    if ($('.spMenu').hasClass('is-open')) {
      bodyFix();
    } else {
      bodyFixReset();
    }
  });
  $('.l-header__nav a').on('click', function () {
    $('.spMenu').click();
  });

  /* ======================================
body fix
====================================== */
  let scrollPosi;

  function bodyFix() {
    scrollPosi = $(window).scrollTop();
    $('body').css({
      position: 'fixed',
      width: '100%',
      'z-index': '1',
      top: -scrollPosi,
    });
  }

  function bodyFixReset() {
    $('body').css({
      position: 'relative',
      width: 'auto',
      top: '0',
    });
    $('html, body').scrollTop(scrollPosi);
  }
});
